import React, { useState, useEffect } from "react";

import axios from "axios";
import { useSnackbar } from "notistack";

import { Icon, IconButton } from "@mui/material";
import { PlayerSearch, PlayerList } from "..";
import RefreshIcon from '@mui/icons-material/Refresh';

const Player = () => {
    const { enqueueSnackbar } = useSnackbar();

    // ---- VARIABLES ----

    const [loading, setLoading] = useState(true);
    const [playlist, setPlaylist] = useState([]);

    // ---- FETCH PLAYLIST ----

    const fetchData = async () => {
        setLoading(true);

        try {
            await axios.get(`${process.env.REACT_APP_API}/song/list`)
                .then(res => {
                    if (res.data?.list) setPlaylist(res.data.list || []);
                    setLoading(false);
                });
        } catch (err: any) {
            enqueueSnackbar(err?.message || "Something went wrong...", { variant: "error" });
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="player flex flex-col gap-3 py-3">
            <PlayerSearch update={fetchData} />
            <div className="flex items-center justify-between select-none">
                <p className="text-sm font-bold opacity-50">SONGS: {playlist.length}</p>
                <IconButton size="large" onClick={() => fetchData()}>
                    <RefreshIcon />
                </IconButton>
            </div>
            <PlayerList loading={loading} list={playlist} />
        </div>
    )
}

export default Player;