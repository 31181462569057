import React, { useState } from "react";

import axios from "axios";

import { IconButton, TextField } from "@mui/material";
import { LoadingButton as Button } from "@mui/lab";
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from "notistack";

interface SongBoosterProps {
    song?: string;
    onClose?: () => void;
}

const SongBooster = ({ song, onClose }: SongBoosterProps) => {
    const { enqueueSnackbar } = useSnackbar();

    const [loading, setLoading] = useState(false);

    // ---- BOOST FUNCTION ----

    const boostSong = async (songName: string, likes: number) => {
        if (loading) return;

        // ---- SUBMIT REQUEST ----
        
        try {
            setLoading(true);

            await axios.post(`${process.env.REACT_APP_API}/song/boost`, { songName, likes })
                .then(res => {
                    enqueueSnackbar("Boosting has started...", { variant: "success" });
                })
                .finally(() => setLoading(false));
        } catch (err: any) {
            setLoading(false);
            enqueueSnackbar(err?.message || "Something went wrong...", { variant: "error" });
        }
    }

    // ---- SUBMIT FORM ----

    const onSubmit = (e: any) => {
        e.preventDefault();

        if ((!e.target?.likes?.value || e.target?.likes?.value < 1 ) || !song) return;
        else boostSong(song, e.target.likes.value);
    }

    return (
        <div className="songBooster p-2 flex flex-col gap-3">
            <div className="flex items-center justify-between">
                <h2 className="px-2 text-xl font-bold select-none">Boost likes</h2>
                {onClose && (
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                )}
            </div>
            <form className="flex flex-col gap-2" onSubmit={onSubmit}>
                <TextField name="likes" type="number" label="Likes count" variant="outlined" fullWidth />
                <Button variant="contained" type="submit" size="large" fullWidth loading={loading}>
                    BOOST SONG
                </Button>
            </form>
        </div>
    )
}

export default SongBooster;