import React, { useState, useEffect } from "react";

import axios from "axios";
import { useSnackbar } from "notistack";

import { Autocomplete, TextField } from "@mui/material";

interface PlayerSearchProps {
    update?: any;
}

const PlayerSearch = ({ update }: PlayerSearchProps) => {
    const { enqueueSnackbar } = useSnackbar();

    // ---- VARIABLES ----

    const [options, setOptions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);

    const [inputValue, setInputValue] = useState("");

    // ---- FETCH RESULTS ----

    const fetchResults = async (value: string) => {
        if (loading) return;

        // Handle empty value
        if (!value) {
            setOptions([]);
            return;
        }

        if (value.length >= 3) {

            // ---- REQUEST ----
        
            try {
                setLoading(true);

                await axios.get(`${process.env.REACT_APP_API}/song/search?query=${encodeURIComponent(value)}`)
                    .then(res => {
                        setOptions(res.data?.list || []);
                    })
                    .finally(() => setLoading(false));
            } catch (err: any) {
                setLoading(false);
                enqueueSnackbar(err?.message || "Something went wrong...", { variant: "error" });
            }
        }
    }

    useEffect(() => {
        fetchResults(inputValue);
    }, [inputValue]);

    // ---- SELECT OPTION ----

    const selectOption = async (value: string|null) => {
        if (!value) return;

        // ---- FIND SONG FROM OPTIONS ----

        let rawTitle = value.split('=>');

        let artist = rawTitle[0]?.trim() || "";
        let title = rawTitle[1]?.trim() || "";

        let song: any = options.filter(item => {
            return (item?.artists[0]?.name === artist && item?.name === title);
        });

        if (!song?.length) return;
        else song = song[0];

        // ---- ADD SONG TO LIST ----
        
        try {
            setLoading(true);

            await axios.post(`${process.env.REACT_APP_API}/song/new`, {
                ...(song?.uri && { song: song.uri }),
                ...(artist && { artistName: artist }),
                ...(song?.name && { songName: song.name }),
                ...(song?.album?.images?.length && { imgSrc: song.album.images[1] ? song.album.images[1].url : song.album.images[0].url }),
                ...(song?.duration_ms && { duration: song.duration_ms })
            })
                .then(res => {
                    enqueueSnackbar("Song added to the playlist", { variant: "success" });
                    if (update) update();
                })
                .finally(() => setLoading(false));
        } catch (err: any) {
            setLoading(false);
            enqueueSnackbar(err?.message || "Something went wrong...", { variant: "error" });
        }
    }

    return (
        <div className="playerSearch">
            <Autocomplete
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (
                    <TextField {...params} fullWidth />
                )}
                options={options.map(item => (`${item?.artists[0]?.name} => ${item?.name}`))}
                onChange={(event: any, newValue: string|null) => selectOption(newValue)}
                noOptionsText="No results"
                loading={loading}
            />
        </div>
    )
}

export default PlayerSearch;