import React from 'react';

import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate } from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from '@mui/material';

import { Player } from './components';

// ---- MUI THEME ----

const theme = createTheme({
  palette: {
    primary: {
      main: '#00A76F'
    }, 
    secondary: {
      main: '#8E33FF'
    }
  }
});

// ---- ROUTER SCHEME ----

const router = createBrowserRouter(createRoutesFromElements(
  <>
    <Route path="/" element={<Player />} />
    <Route path="*" element={<Navigate to="/" />} />
  </>
));

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={5}>
          <RouterProvider router={router} />
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
