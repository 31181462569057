import React, { useState } from "react";

import { Modal, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import SongBooster from "../SongBooster";

export interface PlayerListItemProps {
    song: string;
    artistName: string;
    songName: string;
    imgSrc: string;
    duration: number;
    likes: number;
}

const PlayerListItem = (props: PlayerListItemProps) => {
    const [modal, setModal] = useState(false);

    return (
        <>
            <ListItem className="playerListItem rounded gap-3 select-none cursor-pointer" onClick={() => setModal(true)}>
                <ListItemAvatar>
                    <img className="rounded" src={props?.imgSrc} />
                    <div className="playerListItem__likes">
                        {props?.likes}
                    </div>
                </ListItemAvatar>
                <ListItemText primary={props?.songName} secondary={props?.artistName} />
            </ListItem>

            <Modal className="flex justify-center items-center" open={modal} onClose={() => setModal(false)}>
                <SongBooster song={props?.songName} onClose={() => setModal(false)} />
            </Modal>
        </>
    )
}

export default PlayerListItem;