import React from "react";

import { CircularProgress, List } from "@mui/material";
import PlayerListItem, { PlayerListItemProps } from "../PlayerListItem";

interface PlayerListProps {
    loading?: boolean;
    list?: PlayerListItemProps[];
}

const PlayerList = ({ loading, list = [] }: PlayerListProps) => {
    return (
        <div>
            {loading ? (
                <div className="py-2 text-center">
                    <CircularProgress size={24} />
                </div>
            ) : (
                list?.length ? (
                    <List>
                        {list.sort(({ likes: a }, { likes: b }) => b - a).map((item) => (
                            <PlayerListItem key={item?.song} { ...item } />
                        ))}
                    </List>
                ) : (
                    <div className="text-center select-none py-2">
                        <span className="text-sm font-bold opacity-60">PLAYLIST EMPTY</span>
                    </div>
                )
            )}
        </div>
    )
}

export default PlayerList;